<template>
    <div class="ticketLog">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>日志列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search">
        <span>修改内容</span>
        <el-select v-model="list.logType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getFoodLogList()">查找</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          align="center"
          prop="hotelLogId"
          label="日志ID"
          width="width"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="strType"
          label="修改内容"
          width="width"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="masterName"
          label="修改人"
          width="width"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="hotelAddTime"
          label="修改时间"
          width="width"
        >
        </el-table-column>
        <el-table-column align="center" label="操作" width="width">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="$router.push(`/foodPackLogDetails/${row.hotelLogId}`)"
              >日志详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="list.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="list.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </template>
  <script>
  import { foodPackListLog } from "../../api/food";
  export default {
    name: "ticketLog",
    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },
    data() {
      return {
        options: [
          {
            value: -1,
            label: "全部",
          },
          {
            value: 1,
            label: "日历库存",
          },
          {
            value: 2,
            label: "新增",
          },
          {
            value: 3,
            label: "上/下架",
          },
          {
            value: 4,
            label: "名称",
          },
          {
            value: 5,
            label: "展示图",
          },
          {
            value: 6,
            label: "轮播图",
          },
          {
            value: 7,
            label: "原价",
          },
          {
            value: 8,
            label: "售价",
          },
          {
            value: 9,
            label: "标签",
          },
          {
            value: 10,
            label: "销量",
          },
          {
            value: 11,
            label: "使用日期",
          },
          {
            value: 12,
            label: "不可用日期",
          },
          {
            value: 13,
            label: "使用时间段",
          },
          {
            value: 14,
            label: "使用规则",
          },
          {
            value: 15,
            label: "所属分类Id",
          },
        
          {
            value: 16,
            label: "所属商品id",
          },
          {
            value: 17,
            label: "详情",
          },
          {
            value: 18,
            label: "排序值",
          },
          {
            value: 19,
            label: "库存",
          },
          {
            value: 20,
            label: "支付方式",
          },
          {
            value: 21,
            label: "所需积分",
          },
          {
            value: 22,
            label: "是否可退订",
          },
          
         
        ],
        tableData: [],
        pagination: {},
        list: {
            packId: Number(this.id),
          currentPage: 1,
          pageSize: 5,
          logType: null,
        },
      };
    },
    created() {
      this.getFoodLogList();
    },
    methods: {
      async getFoodLogList() {
        const { data } = await foodPackListLog(this.list);
        console.log(data);
        this.tableData = data.list;
        this.pagination = data.pagination;
      },
      handleSizeChange(num) {
        this.list.pageSize = num;
        this.getFoodLogList();
      },
      handleCurrentChange(num) {
        this.list.currentPage = num;
        this.getFoodLogList();
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .ticketLog {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
    .search {
      margin-top: 50px;
      .el-select {
        margin: 0 20px;
      }
      .select {
        margin: 0 10px;
      }
      .el-input {
        width: 200px;
        margin: 0 20px;
      }
    }
    .el-table {
      margin-top: 50px;
    }
    .el-pagination {
      margin-top: 50px;
      float: right;
    }
  }
  </style>