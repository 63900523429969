import request from '../utils/request'
import baseUrl from './baseUrl'
export const foodCateList = (data) => request({
  url: baseUrl + '/foodCate/selectForBack',
  method: 'POST',
  data
})
export const foodCateListAdd = (data) => request({
  url: baseUrl + '/foodCate/add',
  method: 'POST',
  data
})
export const foodCateListSort = (data) => request({
  url: baseUrl + '/foodCate/modifyDept',
  method: 'POST',
  data
})
export const foodCateListUpdata = (data) => request({
  url: baseUrl + '/foodCate/modify',
  method: 'POST',
  data
})
export const foodCateAllById = (data) => request({
  url: baseUrl + '/foodCate/selectForFatherId',
  method: 'POST',
  data
})
export const foodList = (data) => request({
  url: baseUrl + '/food/selectForBack',
  method: 'POST',
  data
})
export const foodListAdd = (data) => request({
  url: baseUrl + '/food/add',
  method: 'POST',
  data
})
export const foodListDetails = (data) => request({
  url: baseUrl + '/food/selectForId',
  method: 'POST',
  data
})
export const foodListSort = (data) => request({
  url: baseUrl + '/food/modifyDept',
  method: 'POST',
  data
})
export const foodListUpdata = (data) => request({
  url: baseUrl + '/food/update',
  method: 'POST',
  data
})
export const foodListDel = (data) => request({
  url: baseUrl + '/food/delete',
  method: 'POST',
  data
})
export const foodPackageCateList = (data) => request({
  url: baseUrl + '/packCate/selectForBack',
  method: 'POST',
  data
})
export const foodPackageCateListAdd = (data) => request({
  url: baseUrl + '/packCate/add',
  method: 'POST',
  data
})
export const foodPackageCateListUpdata = (data) => request({
  url: baseUrl + '/packCate/modify',
  method: 'POST',
  data
})
export const foodPackageCateListSort = (data) => request({
  url: baseUrl + '/packCate/modifyDept',
  method: 'POST',
  data
})
export const foodPackageCateListDel = (data) => request({
  url: baseUrl + '/packCate/deleteById',
  method: 'POST',
  data
})
export const foodPackageCateListById = (data) => request({
  url: baseUrl + '/packCate/selectAll',
  method: 'POST',
  data
})
export const foodPackList = (data) => request({
  url: baseUrl + '/foodPack/selectForBack',
  method: 'POST',
  data
})
export const foodPackListAdd = (data) => request({
  url: baseUrl + '/foodPack/add',
  method: 'POST',
  data
})
export const foodPackListDetails = (data) => request({
  url: baseUrl + '/foodPack/selectForId',
  method: 'POST',
  data
})
export const foodPackListUpData= (data) => request({
  url: baseUrl + '/foodPack/modify',
  method: 'POST',
  data
})
export const foodPackListDel= (data) => request({
  url: baseUrl + '/foodPack/deleteById',
  method: 'POST',
  data
})
export const foodPackListSort= (data) => request({
  url: baseUrl + '/foodPack/modifyDept',
  method: 'POST',
  data
})
export const foodPackListWriteList= (data) => request({
  url: baseUrl + '/foodPackRedeem/selectForBack',
  method: 'POST',
  data
})
export const foodPackListWriteListAdd= (data) => request({
  url: baseUrl + '/foodPackRedeem/add',
  method: 'POST',
  data
})
export const foodPackListWriteListDel= (data) => request({
  url: baseUrl + '/foodPackRedeem/deleteById',
  method: 'POST',
  data
})
export const foodPackListStock= (data) => request({
  url: baseUrl + '/packPrice/selectForBack',
  method: 'POST',
  data
})
export const foodList2= (data) => request({
  url: baseUrl + '/food/selectForGroupBuy',
  method: 'POST',
  data
})
export const createErFood= (data) => request({
  url: baseUrl + '/food/shareFood',
  method: 'POST',
  data
})
export const createErFoodPack= (data) => request({
  url: baseUrl + '/foodPack/sharePack',
  method: 'POST',
  data
})
export const foodListCopy= (data) => request({
  url: baseUrl + '/food/addCopy',
  method: 'POST',
  data
})
export const foodListPackCopy= (data) => request({
  url: baseUrl + '/foodPack/addCopy',
  method: 'POST',
  data
})
export const foodListLog= (data) => request({
  url: baseUrl + '/foodLog/selectForBack',
  method: 'POST',
  data
})
export const foodPackListLog= (data) => request({
  url: baseUrl + '/foodPackLog/selectForBack',
  method: 'POST',
  data
})
export const foodLogDetails= (data) => request({
  url: baseUrl + '/foodLog/selectForId',
  method: 'POST',
  data
})
export const foodPackLogDetails= (data) => request({
  url: baseUrl + '/foodPackLog/selectForId',
  method: 'POST',
  data
})
export const scenicList= (data) => request({
  url: baseUrl + '/foodScenery/selectForBack',
  method: 'POST',
  data
})
export const scenicListAdd= (data) => request({
  url: baseUrl + '/foodScenery/add',
  method: 'POST',
  data
})
export const scenicListSort= (data) => request({
  url: baseUrl + '/foodScenery/modify',
  method: 'POST',
  data
})
export const scenicListUpdata= (data) => request({
  url: baseUrl + '/foodScenery/update',
  method: 'POST',
  data
})
export const scenicListDel= (data) => request({
  url: baseUrl + '/foodScenery/deleteById',
  method: 'POST',
  data
})
export const getScenery= (data) => request({
  url: baseUrl + '/foodScenery/selectAll',
  method: 'POST',
  data
})
// 批量热门/取消热门
export const modifyHot= (data) => request({
  url: baseUrl + '/food/modifyHot',
  method: 'POST',
  data
})
// 批量推荐/取消推荐
export const modifyRecommendation= (data) => request({
  url: baseUrl + '/food/modifyTj',
  method: 'POST',
  data
})
// 预售美食列表
export const foodList3= (data) => request({
  url: baseUrl + '/food/selectPresale',
  method: 'POST',
  data
})
export const wanlvFoodShow= (data) => request({
  url: baseUrl + '/food/modifyWlShow',
  method: 'POST',
  data
})